<template>
    <div>
        <div>
            <h2 class="th-title">Daftar Pengunjung</h2>
            <!-- <h2 class="th-title">Monitoring Tanggal {{ getHumanDate(monitoringDate) }}</h2> -->
            <!-- <a>
          <p class="text-muted mb-5" @click="showModalDate"> Ganti tanggal monitoring</p>
        </a> -->
            <!-- <a-modal v-model:visible="visibleDate" title="Basic Modal" @ok="handleDate">
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </a-modal> -->
        </div>
        <a-card>
            <div class="d-flex justify-content-between mb-3" v-if="role !== 'dcOwner'">
                <!-- <a-input-search placeholder="Cari Nama atau ID Pengunjung..." @input="handleSearch"
                    v-model:value="search" style="width: 250px;" class="mb-3" /> -->
                <a-date-picker v-model:value="dateVisit" />
                <div>
                    <router-link :to="{ path: `/receptionist/create` }">
                        <a-button>
                            <PlusOutlined />New Visitors
                        </a-button>
                    </router-link>
                </div>
            </div>
            <div>
                <a-table :columns="role !== 'dcOwner' ? columns : columnsDcOwner" :data-source="data" bordered>
                    <!-- <template #name="{ text, record }">
                        <router-link :to="{ path: `/users/${record.id}` }" class="vb__utils__link">
                            {{ text }}
                        </router-link>
                    </template> -->
                    <template #status="{ record }">


                        <a-tag color="red" v-if="record.status === 'in'" style="cursor: pointer;">Belum Keluar</a-tag>
                        <a-tag color="green" v-else-if="record.status === 'out'">Sudah Keluar</a-tag>
                        <a-tag color="red" v-else-if="checkStatusEntry(record) === 'expired'">Sudah
                            Expired</a-tag>
                        <a-tag color="orange" v-else>Sudah Booking</a-tag>
                    </template>
                    <template #action="{ record }">
                        <a-button danger @click="visitorOut(record.id)" v-if="record.status == 'in'"
                            :disabled="record.status === 'out' ? true : false">
                            Keluar
                        </a-button>
                        <a-button danger @click="visitorIn(record.id)" v-if="record.status == 'booked'"
                            :disabled="checkStatusEntry(record) === 'expired'">
                            Masuk
                        </a-button>
                    </template>
                </a-table>
            </div>
        </a-card>
    </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw, watch } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, WarningFilled, LogoutOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
import moment from 'moment'
import {
    generateTask, getTaskByDate,
} from '../../services/axios/modc/eos'
import {
    getVendors,
} from '../../services/axios/modc/vendor'
import {
    getUsers,
} from '../../services/axios/modc/users'
import {
    getVisitors, outVisitor, VisitorIn, cancelVisitors,
} from '../../services/axios/modc/receptionist'
const columns = [
    {
        title: 'No',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'Nama Pengunjung',
        dataIndex: 'name',
        key: 'name',
        slots: { customRender: 'name' },
    },
    {
        title: 'Tipe Pengunjung',
        dataIndex: 'visitorType',
        key: 'name',
        // slots: { customRender: 'name' },
    },
    {
        title: 'Tujuan Berkunjung',
        dataIndex: 'visitType',
        key: 'category',
        // slots: { customRender: 'description' },
    },
    {
        title: 'PIC',
        dataIndex: 'PIC',
        key: 'category',
        // slots: { customRender: 'description' },
    },
    {
        title: 'Tanggal',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Waktu Masuk',
        dataIndex: 'inTime',
        key: 'masuk',
    },
    {
        title: 'Waktu Keluar',
        dataIndex: 'outTime',
        key: 'keluar',
    },
    {
        title: 'Status',
        key: 'out',
        align: 'center',
        slots: { customRender: 'status' },
    },
    {
        title: 'Action',
        key: 'out',
        align: 'center',
        slots: { customRender: 'action' },
    },
    // {
    //     title: 'Keterangan',
    //     key: 'ceklis',
    //     slots: { customRender: 'action' },
    //     align: 'center',
    // },
];
const columnsDcOwner = [
    {
        title: 'No',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'Nama Pengunjung',
        dataIndex: 'name',
        key: 'name',
        slots: { customRender: 'name' },
    },
    {
        title: 'Tipe Pengunjung',
        dataIndex: 'visitorType',
        key: 'name',
        // slots: { customRender: 'name' },
    },
    {
        title: 'Tujuan Berkunjung',
        dataIndex: 'visitType',
        key: 'category',
        // slots: { customRender: 'description' },
    },
    {
        title: 'PIC',
        dataIndex: 'PIC',
        key: 'category',
        // slots: { customRender: 'description' },
    },
    {
        title: 'Tanggal',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Waktu Masuk',
        dataIndex: 'inTime',
        key: 'masuk',
    },
    {
        title: 'Waktu Keluar',
        dataIndex: 'outTime',
        key: 'keluar',
    },
    {
        title: 'Status',
        key: 'out',
        align: 'center',
        slots: { customRender: 'status' },
    },
    // {
    //     title: 'Keterangan',
    //     key: 'ceklis',
    //     slots: { customRender: 'action' },
    //     align: 'center',
    // },
];

export default {
    components: {
        PlusOutlined,
    },

    setup() {
        const store = useStore()
        let data = ref([])
        let search = ref(null)
        let modalAddVisible = ref(false)
        let formAdd = reactive({
            name: null,
            description: null,
        })
        let formEdit = ref({
            name: null,
            description: null,
        })
        let idEdit = ref(null)
        let dateVisit = ref(moment(new Date()))
        const loadingAdd = ref(false)
        const rulesRef = reactive({
            name: [
                {
                    required: true,
                    message: 'Nama harus diisi!',
                    trigger: 'change',
                },
            ],
        })

        onMounted(() => {
            fetchCategory()
            const storedUserData = localStorage.getItem('userData')
            const userData = JSON.parse(storedUserData)
            role.value = userData.user.role
        })

        const fetchCategory = async (search = '') => {
            const payload = {
                eosId: 1,
                date: moment(monitoringDate.value).format('YYYY-MM-DD'),
            }
            const storedUserData = localStorage.getItem('userData');
            const userData = JSON.parse(storedUserData);
            const { visitors } = await getVisitors(userData.user.id, moment(dateVisit.value).format('YYYY-MM-DD'))
            visitors.forEach((e, idx) => {
                e.status = 'booked'
                e.key = idx + 1
                if (e.inTime) {
                    e.inTime = moment(e.inTime).format('HH:mm A DD-MM-YYYY')
                    e.status = 'in'
                }
                if (e.outTime) {
                    e.outTime = moment(e.outTime).format('HH:mm A DD-MM-YYYY')
                    e.status = 'out'
                }
            })
            data.value = visitors
        }

        const showModalAdd = () => {
            modalAddVisible.value = true
        }

        const handleOk = () => {
            modalAddVisible.value = false
        }

        const closeEdit = () => idEdit.value = null

        const { validate: validateEdit, validateInfos: validateInfosEdit } = useForm(formEdit, rulesRef);
        const confirmEdit = async (record) => {
            try {
                const payload = {
                    id: record.key,
                    data: {
                        name: formEdit.value.name,
                        description: formEdit.value.description,
                    },
                }
                const tes = await validateEdit()
                // const tes2 = await validate()
                Modal.confirm({
                    title: `Apa anda yakin ingin mengubah data pada kategori '${record.name}'?`,
                    icon: createVNode(ExclamationCircleOutlined),
                    async onOk() {
                        try {
                            const data = await store.dispatch('category/UPDATE_CATEGORY', payload)
                            modalAddVisible.value = false
                            fetchCategory()
                            notification.success({
                                message: `Berhasil Mengubah Data Pada Kategori '${record.name}'`,
                            })
                            idEdit.value = null
                        } catch (err) {
                            console.log(err)
                        }
                    },
                    onCancel() { },
                })
            } catch (err) {
                console.log(err)
            }
        }

        const { resetFields, validate, validateInfos } = useForm(formAdd, rulesRef);

        const confirmAdd = async () => {
            try {
                await validate()
                // console.log(console.log(toRaw(formAddRef)))
                Modal.confirm({
                    title: 'Apa anda yakin ingin menambahkan kategori?',
                    icon: createVNode(ExclamationCircleOutlined),
                    async onOk() {
                        try {
                            const data = await store.dispatch('category/ADD_CATEGORY', { ...formAdd })
                            modalAddVisible.value = false
                            fetchCategory()
                            notification.success({
                                message: `Berhasil Menambahkan '${formAdd.name}' Dari Daftar Kategori`,
                            })
                            resetFields()
                        } catch (err) {
                            console.log(err)
                        }
                    },
                    onCancel() { },
                })
            } catch (err) { }
        }

        const categoryAction = async (record, action) => {
            const data = {
                id: record.key,
                data: {
                    name: record.name,
                    description: record.description,
                },
            }
            if (action === 'DELETE') {
                Modal.confirm({
                    title: `Apa anda yakin ingin menghapus kategori '${record.name}' dari daftar?`,
                    icon: createVNode(ExclamationCircleOutlined),
                    async onOk() {
                        try {
                            const res = await store.dispatch('category/DELETE_CATEGORY', { ...data })
                            fetchCategory()
                            notification.success({
                                message: `Berhasil Menghapus '${record.name}' Dari Daftar Kategori`,
                            })
                        } catch (err) {
                            console.log(err)
                        }
                    },
                    onCancel() { },
                })
            } else if (action === 'UPDATE') {
                idEdit.value = record.key
                formEdit.value = {
                    name: record.name,
                    description: record.description || null,
                }
                // const data = await store.dispatch(action + '_CATEGORY', { id, data })
            }
        }

        const handleSearchCategory = () => {
            fetchCategory(search.value)
        }

        const visibleDate = ref(false);
        const showModalDate = () => {
            visibleDate.value = true;
        };
        const handleDate = e => {
            visible.value = false;
        };

        const monitoringDate = ref()
        watch(dateVisit, (newValue, oldValue) => {
            // Fungsi ini akan berjalan setiap kali myVariable berubah
            // console.log('berubah')
            // Panggil fungsi atau lakukan tindakan lain yang Anda inginkan di sini
            fetchCategory();
        });

        const generateMonitoringTask = async () => {
            const payload = {
                eosId: 1,
                customerId: 1,
                locationId: 1,
                date: moment(monitoringDate.value).format('YYYY-MM-DD'),
            }
            const data = await generateTask(payload)
            fetchCategory()

        }

        const visitorIn = async (id) => {
            await VisitorIn(id)
            fetchCategory()
            // console.log(id)
        }
        const visitorOut = async (id) => {
            await outVisitor(id)
            fetchCategory()
            // console.log(id)
        }

        const visitorCancel = async () => {
            const storedUserData = localStorage.getItem('userData');
            const userData = JSON.parse(storedUserData);
            await cancelVisitors(userData.user.id)
            fetchCategory()
        }
        const disabledDate = current => {
            // Can not select days before today and today
            return current && current < moment().endOf('day');
        };
        const checkStatusEntry = (record) => {
            // console.log(record.date)
            const hariIni = new Date();
            const tanggalDiberikan = new Date(record.date);

            // Membandingkan tanggal tanpa mempertimbangkan waktu
            hariIni.setHours(0, 0, 0, 0);
            tanggalDiberikan.setHours(0, 0, 0, 0);

            if (tanggalDiberikan < hariIni) {
                return 'expired';
            } else {
                return 'masih bisa';
            }
        }
        const role = ref('')

        return {
            role,
            visitorOut,
            monitoringDate,
            visibleDate,
            showModalDate,
            handleDate,
            data,
            columns,
            columnsDcOwner,
            search,
            modalAddVisible,
            showModalAdd,
            handleOk,
            formAdd,
            rulesRef,
            loadingAdd,
            confirmAdd,
            validate,
            validateInfos,
            validateEdit,
            validateInfosEdit,
            categoryAction,
            idEdit,
            formEdit,
            closeEdit,
            confirmEdit,
            handleSearchCategory,
            generateMonitoringTask,
            visitorIn,
            visitorCancel,
            checkStatusEntry,
            dateVisit,
            disabledDate,
        }

    },

    methods: {
        getHumanDate: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
    },
}
</script>